import React from 'react';

import { Form, Container, Row, Col } from 'react-bootstrap'

import PageBanner from "../page/PageBanner";
import CountyNotes from "../page/CountyNotes";
import PrimaryButton from "./PrimaryButton";
import ClearButton from "./ClearButton";
import CancelButton from "./CancelButton";
import SecondaryButton from "./SecondaryButton";
import CreditCardSearchAuthWindow from "./CreditCardSearchAuthWindow";
import CreditCardPriceListWindow from "./CreditCardPriceListWindow";
import CreditCardPriceListReAuthWindow from "./CreditCardPriceListReAuthWindow"
import { connect } from "react-redux";
import FieldRow from './FieldRow';
import { checkIconGreen, spinnerIconGray } from "../../../img/font_awesome/font_awesome"
import Loading from '../modal/Loading';

const mapStateToProps = state => {
  return {
    sideNavigationOpen: state.sideNavigationReducer
  };
}

class FormTemplate extends React.Component {

  render() {

    // The authorize.net window in CreditCardSearchAuthWindow needs to re-render every time apparently.  I tried to use the authorize.net token as the component key, but apparently, 
    // the token either is too big or has characteers that didn't work out. Therefore, I'm using a random number to force it re-render each time.
    const creditCardAuthorizeDotNetWindowKey = Math.ceil(Math.random() * 1000000000) + "";

    let formTemplateMarginLeft = 60
    let formTemplateMarginRight = 20
    if (this.props.formOnly) {
      formTemplateMarginLeft = 0
      formTemplateMarginRight = 0
    } else if (this.props.sideNavigationOpen) {
      formTemplateMarginLeft = 270
    } 

    let autoComplete = this.props.autoComplete === false ? "off" : "on";

    // Convert from legacy single secondary button into newer unlimited buttons.
    let secondaryButtons = this.props.secondaryButtons;

    if (!secondaryButtons) {
        if (this.props.onSeconaryClick) {
          secondaryButtons = [{ onClick: this.props.onSeconaryClick, label: this.props.secondaryButtonLabel }]
        } else {
          secondaryButtons = [];
        }
    }

    return (
      <div style={{ marginLeft: formTemplateMarginLeft, marginRight: formTemplateMarginRight, marginTop:4, paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"  }}>
        <CountyNotes componentName={this.props.componentName} />
        <PageBanner pageHeading={this.props.pageHeading} buttons= {this.props.pageButtons}/>
        <Container fluid="true" style={{ paddingLeft: 50, paddingBottom: 20, marginTop: 10, marginLeft: 4, marginRight: 4, border: '3px solid darkGray', borderRadius: '5px' }}>
          <Row>&nbsp;</Row>
          <Row>
          <Col>
          <Form autocomplete={autoComplete}  onSubmit={this.props.onSubmit} style={{paddingRight: 10}}>
            {
              this.props.formGroupRows.map((formGroupRow, index) => {
                return (
                  <>
                    {formGroupRow.heading && <h5 style={{textDecoration: 'underline'}}>{formGroupRow.heading}</h5>}
                    <FieldRow key={this.props.componentName + "FieldRow" + index} formGroupRow={formGroupRow} disabled={this.props.loadingIndicator} onChange={this.props.onChange} />
                  </>
                );
              })
            }
            {this.props.afterRowsContent}

            <Row>&nbsp;</Row>
            <PrimaryButton buttonLabel={this.props.primaryButtonLabel} disabled={this.props.loadingIndicator} />&nbsp;&nbsp;
            
            { 
              this.props.successIndicator && !this.props.onSeconaryClick && !this.props.onClear &&
              <span style={{width:18, verticalAlign: "bottom", paddingBottom: 1, display:"inline-block"}}>{checkIconGreen}</span>
            }
            { 
              this.props.loadingIndicator && !this.props.onSeconaryClick && !this.props.onClear &&
              <span style={{width:18, verticalAlign: "bottom", marginBottom: 1, display:"inline-block"}}>{spinnerIconGray}</span>
            }

            {
              (secondaryButtons.length > 0) && secondaryButtons.map((button, index) => {
                return <>
                  <SecondaryButton buttonBlue={this.props.secondaryButtonBlue} buttonLabel={button.label} onClick={button.onClick} />
                  &nbsp;&nbsp;
                  </>;
              })
            }
            { 
              this.props.successIndicator && this.props.onSeconaryClick &&
              <>&nbsp;&nbsp;<span style={{width:18, verticalAlign: "bottom", paddingBottom: 1, display:"inline-block"}}>{checkIconGreen} </span></>
            }
            { 
              this.props.loadingIndicator && this.props.onSeconaryClick &&
              <>&nbsp;&nbsp;<span style={{width:18, verticalAlign: "bottom", marginBottom: 1, display:"inline-block"}}>{spinnerIconGray} </span></>
              && <>&nbsp;&nbsp;</>
            }
            
            {
              this.props.onClear &&
              <ClearButton onClear={this.props.onClear} buttonLabel={this.props.clearButtonLabel}/>
            }
            { 
              this.props.successIndicator && this.props.onClear &&
              <>&nbsp;&nbsp;<span style={{width:18, verticalAlign: "bottom", paddingBottom: 1, display:"inline-block"}}>{checkIconGreen} </span></>
            }
            { 
              this.props.loadingIndicator && this.props.onClear &&
              <>&nbsp;&nbsp;<span style={{width:18, verticalAlign: "bottom", marginBottom: 1, display:"inline-block"}}>{spinnerIconGray} </span></>
            }
            {
              this.props.onCancel &&
              <CancelButton onCancel={this.props.onCancel} />
            }
            {
              this.props.submitMessage &&
              <span style={{width:18}}>&nbsp;&nbsp;{this.props.submitMessage}</span>
            }
          </Form>
          </Col>
          { 
          
            this.props.formRightArea &&
            <Col sm={4} className='align-self-end' >
              {
                this.props.formRightArea
              }
            </Col>
          }
          </Row>
        </Container>
        <Loading loadingIndicator={this.props.loadingIndicator} />
        <CreditCardPriceListWindow show={this.props.creditCardPriceListWindowShow} continueLabel="Continue to Credit Card" priceListHeading="Credit Card Preauthorization" handleContinueClicked={this.props.handleCreditCardPriceListContinue} handleCloseClicked={this.props.handleCreditCardPriceListClose} />
        {this.props.creditCardAuthorizeDotNetWindowShow && <CreditCardSearchAuthWindow show={this.props.creditCardAuthorizeDotNetWindowShow} handleAuthorizedSubmit={this.props.handleAuthorizedSubmit} handleCloseClicked={this.props.handleCreditCardAuthorizeDotNetWindowClose} token={this.props.authorizeDotNetToken} />}
        <CreditCardPriceListReAuthWindow show={this.props.creditCardPriceListReAuthWindowShow} handleContinueClicked={this.props.handleCreditCardPriceListReAuth} handleCloseClicked={this.props.handleCreditCardPriceListReAuthWindowClose} />
      </div>
    )
  }
}

export default connect(mapStateToProps)(FormTemplate);