import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, EscrowControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";
import AjaxResponseHandler from "../../utils/AjaxResponseHandler"

const componentName = EscrowControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class Escrow extends Component {

  state = {
    applySurcharge: false,
    feeCode: "",
    chargeCode: "",
    reloadByCreditCard: false,
    minReloadAmount: 0.0,
    maxReloadAmount: 0.0,
    creditCardSurchargeMethod: "dollars",
    creditCardSurcharge: 0.0,
    creditCardSurchargePercent: 0.0,

    status: "",
    message: "",
    errors: {}
  }

  handleValidation = () => {
    let hasFeeCode = this.state.feeCode.trim().length > 0;
    let hasChargeCode = this.state.chargeCode.trim().length > 0;
    
    let errors = {};
    if (hasFeeCode && !hasChargeCode) {
      errors["chargeCode"] = "Required with Fee Code.";
      this.setState({ errors });
      return false;
    } else if (hasChargeCode && !hasFeeCode) {
      errors["feeCode"] = "Required with Charge Code.";
      this.setState({ errors });
      return false;
    }

    if (this.state.applySurcharge === true || this.state.applySurcharge === 'true') {
      if (this.state.creditCardSurchargeMethod === "dollars" && this.state.creditCardSurcharge === 0) {
        errors["creditCardSurcharge"] = "Required.";
        this.setState({ errors });
        return false;
      }
      if (this.state.creditCardSurchargeMethod === "percent" && this.state.creditCardSurchargePercent === 0) {
        errors["creditCardSurchargePercent"] = "Required.";
        this.setState({ errors });
        return false;
      }
    }

    if (this.state.reloadByCreditCard === true || this.state.reloadByCreditCard === 'true') {
      if (this.state.minReloadAmount === 0) {
        errors["minReloadAmount"] = "Required.";
        this.setState({ errors });
        return false;
      }
      if (this.state.maxReloadAmount === 0) {
        errors["maxReloadAmount"] = "Required.";
        this.setState({ errors });
        return false;
      }
      if (parseFloat(this.state.maxReloadAmount) < parseFloat(this.state.minReloadAmount)) {
        errors["maxReloadAmount"] = "Cannot be less than Min $.";
        this.setState({ errors });
        return false;
      }
    }
   
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.handleValidation()) {
      console.log(this.state);

      return;
    }

    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }
   
    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/escrow", this.state, { withCredentials: true })
    .then(
      (response) => {
   
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        AjaxResponseHandler.handleError(error, null, this.props.history);
        this.setState({ 
          status: ""
        });
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;

    this.setState({
      status: "",
      errors: {},
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

	componentDidMount() {
    this.props.setSelectedControlPanelType();
  
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/escrow", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          applySurcharge: content.applySurcharge,
          feeCode: content.feeCode,
          chargeCode: content.chargeCode,
          reloadByCreditCard: content.reloadByCreditCard,
          minReloadAmount: content.minReloadAmount,
          maxReloadAmount: content.maxReloadAmount,
          creditCardSurchargeMethod: content.creditCardSurchargeMethod,
          creditCardSurcharge: content.creditCardSurcharge,
          creditCardSurchargePercent: content.creditCardSurchargePercent,
          status : ""
        });
      },
      (error) => {
        AjaxResponseHandler.handleError(error, null, this.props.history);
      }
    );
  }
  
  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Escrow",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          heading: 'LandLink',
          formGroups: [{ id: 'feeCode', label: 'Fee Code', fieldType: 'text', value: this.state.feeCode, error: this.state.errors['feeCode'] }]
        },
        {
          formGroups: [{ id: 'chargeCode', label: 'Charge Code', fieldType: 'text', value: this.state.chargeCode, error: this.state.errors['chargeCode']}]
        },
        {
          heading: 'Reload from LandShark',
          formGroups: [{ id: 'reloadByCreditCard', label: 'Reload by Credit Card', fieldType: 'select', value: this.state.reloadByCreditCard}]
        },
        {
          formGroups: [{ id: 'minReloadAmount', label: 'Min Reload Per Charge ($)', fieldType: 'number', numberType: 'decimal', disableField: this.state.reloadByCreditCard === false, value: this.state.minReloadAmount, error: this.state.errors['minReloadAmount']},
                       { id: 'maxReloadAmount', label: 'Max Reload Per Charge ($)', fieldType: 'number', numberType: 'decimal', disableField: this.state.reloadByCreditCard === false, value: this.state.maxReloadAmount, error: this.state.errors['maxReloadAmount']}
                      ]
        },
        {
          heading: 'Reload Surcharge',
          formGroups: [{ id: 'applySurcharge', label: 'Apply Surcharge', fieldType: 'select', value: this.state.applySurcharge }]
        },
        {
          formGroups: [{ id: 'creditCardSurchargeMethod', label: 'Dollars or Percent', fieldType: 'select', disableField: this.state.applySurcharge === false, options: [{code: 'dollars', description: 'Dollars'}, {code: 'percent', description: 'Percent'}],  value: this.state.creditCardSurchargeMethod }]         
        },
        {
          formGroups: [{ id: 'creditCardSurcharge', label: 'Credit Card Surcharge ($)', fieldType: 'number', numberType: 'decimal', hideRow: this.state.creditCardSurchargeMethod !== 'dollars',  disableField: this.state.applySurcharge === false, value: this.state.creditCardSurcharge, error: this.state.errors['creditCardSurcharge'] }]
        },
        {
          formGroups: [{ id: 'creditCardSurchargePercent', label: 'Credit Card Surcharge (%)', fieldType: 'number', numberType: 'decimal', hideRow: this.state.creditCardSurchargeMethod !== 'percent',  disableField: this.state.applySurcharge === false, value: this.state.creditCardSurchargePercent, error: this.state.errors['creditCardSurchargePercent']}]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(Escrow);