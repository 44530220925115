import React from 'react';
import { connect } from "react-redux";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, AccountsControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import axios from "axios";
import { LOADING_STATUES } from "../../components/constants/constants";
import Alert from '../common/modal/Alert';

const componentName = AccountsControlPanelKey;

const mapStateToProps = state => {
	return {
		sideNavigationOpen: state.sideNavigationReducer
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
	}
}

class AccountReload extends React.Component {
	state = {
		id: 0,
		imagesRemaining: 0,
		currentImagesRemaining: 0,
		loadingStatus: '',     
		showAlert: false,
		alertMessage: "",
		redirect: false
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value ? event.target.value : 0
		})
	}

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountFlatFeeUsage", this.state, { withCredentials: true })
				.then((response) => {
					this.setState({loadingStatus: "", redirect: true}, this.showAlert(response.data.message))
				}, (error) => {
					this.setState({loadingStatus: "", redirect: false}, this.showAlert(error.message));
				});
		}
	}

	handleCancel = (event) => {
		event.preventDefault();
		this.props.history.push('/controlPanel/accounts');
	}

	showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	hideLoading = (message, status) => {
		if (status === 'ERROR') {
			this.setState({
				loadingStatus: ""
			})
		} else {
			this.setState({
				loadingStatus: ""
			})
		}
	}
	
	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	
		if (this.state.redirect) {
			this.props.history.push('/controlPanel/accounts');
		}
	}
	
	componentDidMount() {
		axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/accountFlatFeeUsage/" + this.props.match.params.id, { withCredentials: true })
		.then(
		  (response) => {
			const content = response.data.content;
			this.setState( {
				id: content.id,
				imagesRemaining: content.imagesRemaining,
				currentImagesRemaining: content.imagesRemaining
			});
			
		  },
		  (error) => {
			console.log(error);
		  }
		);
	  }

	render() {		
		const formProps = {
			componentName: "AccountReload",
			pageHeading: "Flat Fee Usage Override - " + this.props.match.params.name , 
			onChange: this.handleChange,
			onSubmit: this.handleSubmit,
			onCancel: this.handleCancel,
			formGroupRows: [			
				{
					formGroups: [{ id: 'imagesRemaining', label: 'New Balance (Current Balance: ' + this.state.currentImagesRemaining + ')', fieldType: 'number', numberType:'integer', value: this.state.imagesRemaining, required: true }]
				}
			]
		}

		return (
			<>
				<FormTemplate {...formProps} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
			</>
			)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountReload);
