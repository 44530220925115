import React, { Component } from "react"
import { Container, Col, Row, Table, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import SearchResultsDetailsNoResults from './SearchResultsDetailsNoResults'
import { searchByTaxLien, loadDocuments, SearchByDocumentNumberUrl, selectSearchSequenceRow, setSelectedSearchType, SearchByDocumentNumberKey, } from "../../../redux/actions/searchActions";
import Paginator from '../../common/page/Paginator'
import { getPaginatorHelper } from '../../common/page/PaginatorHelper'
import Loading from '../../common/modal/Loading'
import { LOADING_STATUES } from "../../constants/constants";

export const SearchResultsDetailsTaxpayersComponetName = 'SearchResultsDetailsTaxpayers';
export const SearchResultsDetailsReleasesComponetName = 'SearchResultsDetailsReleases';
export const SearchResultsDetailsRevisionsComponetName = 'SearchResultsDetailsRevisions';

const mapStateToProps = state => {
    return {
        searchResults: state.searchResultsReducer,
        searchResultsDetails: state.searchDetailsTaxLienReducer,
        bookLabel: state.countyConfigCommonReducer.bookLabel,
        bookType: state.landlinkConfigReducer.bookType,
        currentUser: state.currentUserReducer,
        paginationPageTaxpayers: state.searchDetailsTaxpayersPaginationReducer,
        paginationPageReleases: state.searchDetailsReleasesPaginationReducer,
        paginationPageRevisions: state.searchDetailsRevisionsPaginationReducer,
        tableLoading: state.searchResultsTableLoadingReducer,
        currentSearch: state.searchHistoryReducer.searchHistoryList[0],
        currentSort: state.searchResultsSortReducer,
        currentPageNumber: state.searchResultsPaginationReducer,
        searchSequence: state.searchSequenceStartedReducer,
        searchSequenceSelectedDocumentId: state.searchResultsTableSelectedRowReducer
    };
}

const matchDispatchToProps = (dispatch) => {
    return {
        searchByTaxLien: (searchCriteria, history, callback) => dispatch(searchByTaxLien(searchCriteria, history, callback)),
        searchByDocumentNumber: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByDocumentNumberUrl)),
        startSearchSequence: (currentSearch) => dispatch({ type: 'START_SEARCH_SEQUENCE', search: currentSearch}),
        selectSearchSequenceRow: (id) =>  dispatch(selectSearchSequenceRow(id)),
        setSelectedSearchType: (searchType) => dispatch(setSelectedSearchType(searchType))
    }
}

class TaxLienSearchResultsDetails extends Component {

    // May not need a loadingStatus, hence a state. 
    // Leave the below three parts of code here for now.
    state = {
        loadingStatus: ""
    }

    // May not need a hideLoading.  
    // Loading is needed only if you can trigger a search from details, as in SearchResultsDetails.  
    // Not sure if there's anything to click in TaxLienSearchResultsDetails.
    hideLoading = (message, status) => {
        if (status === 'ERROR') {
            alert(message);
        }

        this.setState({
            loadingStatus: ""
        })

    }

    handleDocumentClick = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: LOADING_STATUES.LOADING
        })

        if (!this.props.searchSequence) {
            let currentSearchCriteria = this.props.currentSearch;
            currentSearchCriteria.pageNumber = this.props.currentPageNumber;
            currentSearchCriteria.sortKey = this.props.currentSort.sortKey;
            currentSearchCriteria.sortDirection = this.props.currentSort.sortDirection;
            this.props.startSearchSequence(currentSearchCriteria); 
            this.props.selectSearchSequenceRow(this.props.searchResultsDetails);
        }

        let searchCriteria = {
            documentType: event.target.getAttribute("data-documenttype"),
            fromDocumentNumber: event.target.getAttribute("data-documentnumber"), 
            toDocumentNumber: ''
        }

        this.props.setSelectedSearchType(SearchByDocumentNumberKey);
        this.props.searchByDocumentNumber(searchCriteria, this.props.history, this.hideLoading); 
    }

    handleLienDocumentClick = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: LOADING_STATUES.LOADING
        })

        if (!this.props.searchSequence) {
            let currentSearchCriteria = this.props.currentSearch;
            currentSearchCriteria.pageNumber = this.props.currentPageNumber;
            currentSearchCriteria.sortKey = this.props.currentSort.sortKey; 
            currentSearchCriteria.sortDirection = this.props.currentSort.sortDirection;
            this.props.startSearchSequence(currentSearchCriteria); 
            this.props.selectSearchSequenceRow(this.props.searchResultsDetails);
        }

        let searchCriteria = {
            currentOrExpired: 'ALL',
            searchByTaxLienMethod: 'DOCUMENTNUMBER',
            documentNumber: event.target.getAttribute("data-documentnumber"),
            documentType: event.target.getAttribute("data-documenttype")
        }

        this.props.searchByTaxLien(searchCriteria, this.props.history, this.hideLoading);
    }

    handleTaxpayerClick = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: LOADING_STATUES.LOADING
        })

        if (!this.props.searchSequence) {
            let currentSearchCriteria = this.props.currentSearch;
            currentSearchCriteria.pageNumber = this.props.currentPageNumber;
            currentSearchCriteria.sortKey = this.props.currentSort.sortKey;
            currentSearchCriteria.sortDirection = this.props.currentSort.sortDirection;
            this.props.startSearchSequence(currentSearchCriteria); 
            this.props.selectSearchSequenceRow(this.props.searchResultsDetails);
        }

        let searchCriteria = {
            currentOrExpired: 'ALL',
            searchByTaxLienMethod: 'TAXPAYER',
            taxpayerName: event.target.getAttribute("data-taxpayername")
        }

        this.props.searchByTaxLien(searchCriteria, this.props.history, this.hideLoading);
    }

    render() {

        const numberOfVisibleRecords = 3;

        let bookTypeLabel = "";
        if (this.props.bookType === true){
            bookTypeLabel = "/Type";
        }

        if (this.props.searchResultsDetails === 0) {
            return (<SearchResultsDetailsNoResults />)
        } 

        let selectedSearchResult = this.props.searchResults.searchResultList.find((searchResult) => {
            return this.props.searchResultsDetails == searchResult.docId;
        });

        let { docType, docNumber, displayDocNumber, lienNumber, instrumentDescription, lienDate, recordedDateTime, instrumentDate, totalAmount, lienType,
            taxpayerSearchResults, releaseSearchResults, revisionSearchResults, displayBookAndPage } = selectedSearchResult;

        let numberOfTaxpayerSearchResultsPages = Math.ceil ( taxpayerSearchResults.length / numberOfVisibleRecords );
        let paginationHelperTaxpayers = getPaginatorHelper(this.props.paginationPageTaxpayers, taxpayerSearchResults, numberOfVisibleRecords);

        let numberOfReleaseSearchResultsPages = Math.ceil ( releaseSearchResults.length / numberOfVisibleRecords );
        let paginationHelperReleases = getPaginatorHelper(this.props.paginationPageReleases, releaseSearchResults, numberOfVisibleRecords);

        let numberOfRevisionSearchResultsPages = Math.ceil ( revisionSearchResults.length / numberOfVisibleRecords );
        let paginationHelperRevisions = getPaginatorHelper(this.props.paginationPageRevisions, revisionSearchResults, numberOfVisibleRecords);

        return (
            <>
                            <Col style={{ paddingLeft: 0, marginRight: 0, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} >
                <Container key={this.props.searchResultsDetails} style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 0, paddingBottom: 10, marginRight: 0 }}  fluid="true">

                        <Row >
                            <Col>
                                <h5>
                                    <span><strong>{instrumentDescription}</strong> </span>
                                    <span className="badge badge-secondary">{lienType}</span>
                                </h5>
                            </Col>
                        </Row>
                        <hr style={{ background: 'black', marginTop: 2, marginBottom: 2 }}></hr>

                        <div>
                            <div>
                                <strong>Document #: </strong> 
                                <a name="searchByDocumentNumber" href='#BogusToRemoveWarning' style={{ textDecoration: 'none' }} data-documentnumber={docNumber} data-documenttype={docType} onClick={this.handleDocumentClick}> {displayDocNumber}</a>
                            </div>
                            <div><strong>Recorded Date: </strong>{recordedDateTime}</div>
                            <div><strong>Lien Date: </strong>{lienDate}</div>
                            <div><strong>Lien #: </strong>{lienNumber} &nbsp;</div>
                            <div><strong>Instrument Date: </strong>{instrumentDate}</div>
                            <div><strong>Total Amount: </strong>{totalAmount}</div>
                            <div><strong>{this.props.bookLabel}/Page{bookTypeLabel}: </strong>{displayBookAndPage}</div>

                            <Table striped bordered hover variant="dark" size="sm" style={{ marginBottom: 0, marginTop: 10 }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '34%' }} scope="col">Taxpayer</th>
                                        <th style={{ width: '33%' }} scope="col">Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paginationHelperTaxpayers.currentPageRecordList.map(taxpayer => {
                                            return <tr>
                                                
                                                <td className="table-td-xs">
                                                    {
                                                        this.props.tableLoading === true &&
                                                        <span>{taxpayer.name}</span>
                                                    }
                                                    {
                                                        this.props.tableLoading !== true &&
                                                        <a name="searchByTaxLienTaxpayer" href='#BogusToRemoveWarning' style={{ textDecoration: 'none' }} data-taxpayername={taxpayer.name} onClick={this.handleTaxpayerClick}> {taxpayer.name}</a>
                                                    }
                                                </td>
                                    
                                                <td className="table-td-xs">
                                                    {taxpayer.displayAddress}
                                                </td>

                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Row>
                                <Col><Paginator componentName={SearchResultsDetailsTaxpayersComponetName} numberOfPages={numberOfTaxpayerSearchResultsPages} numberOfVisibleRecords={numberOfVisibleRecords}  ></Paginator></Col>
                            </Row>

                            <Table striped bordered hover variant="dark" size="sm" style={{ marginBottom: 0, marginTop: 10 }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '10%' }} scope="col">Revision</th>
                                        <th style={{ width: '23%' }} scope="col">Document</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paginationHelperRevisions.currentPageRecordList.map(amendment => {
                                            return <tr>
                                                <td className="table-td-xs">
                                                    {amendment.flag}
                                                </td>
                                                <td className="table-td-xs">
                                                    {
                                                        this.props.tableLoading === true &&
                                                        <span>{amendment.displayDocNumber}</span>
                                                    }
                                                    {
                                                        this.props.tableLoading !== true &&
                                                        <a name="searchByTaxLienDocumentNumber" href='#BogusToRemoveWarning' style={{ textDecoration: 'none' }} data-documentnumber={amendment.docNumber} data-documenttype={amendment.docType} onClick={this.handleLienDocumentClick}> {amendment.displayDocNumber}</a>
                                                    }
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Row>
                                <Col><Paginator componentName={SearchResultsDetailsRevisionsComponetName} numberOfPages={numberOfRevisionSearchResultsPages} numberOfVisibleRecords={numberOfVisibleRecords}  ></Paginator></Col>
                            </Row>
   
                            <Table striped bordered hover variant="dark" size="sm" style={{ marginBottom: 0, marginTop: 10 }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '10%' }} scope="col">Release</th>
                                        <th style={{ width: '23%' }} scope="col">Released By</th>
                                        <th style={{ width: '22' }} scope="col">Date</th>
                                        <th style={{ width: '23%' }} scope="col">Revoked By</th>
                                        <th style={{ width: '22%' }} scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paginationHelperReleases.currentPageRecordList.map(release => {
                                            return <tr>
                                                <td className="table-td-xs">
                                                    {release.partialFullFlag}
                                                </td>
                                                <td className="table-td-xs">
                                                    {
                                                        this.props.tableLoading === true &&
                                                        <span>{release.releaseDisplayDocNumber}</span>
                                                    }
                                                    {
                                                        this.props.tableLoading !== true &&
                                                        <a name="searchByDocumentNumber" href='#BogusToRemoveWarning' style={{ textDecoration: 'none' }} data-documentnumber={release.releaseDocNumber} data-documenttype={release.releaseDocType} onClick={this.handleDocumentClick}> {release.releaseDisplayDocNumber}</a>
                                                    }
                                                    </td>
                                                <td className="table-td-xs">
                                                    {release.releaseDate}
                                                </td>
                                                <td className="table-td-xs">
                                                    {
                                                        this.props.tableLoading === true &&
                                                        <span>{release.revokeDisplayDocNumber}</span>
                                                    }
                                                    {
                                                        this.props.tableLoading !== true &&
                                                        <a name="searchByDocumentNumber" href='#BogusToRemoveWarning' style={{ textDecoration: 'none' }} data-documentnumber={release.revokeDocNumber} data-documenttype={release.revokeDocType} onClick={this.handleDocumentClick}> {release.revokeDisplayDocNumber}</a>
                                                    }
                                                    </td>
                                                <td className="table-td-xs">
                                                    {release.revokeDate}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Row>
                                <Col><Paginator componentName={SearchResultsDetailsReleasesComponetName} numberOfPages={numberOfReleaseSearchResultsPages} numberOfVisibleRecords={numberOfVisibleRecords}  ></Paginator></Col>
                            </Row>

                        </div>
                    </Container>
                </Col>
                <Loading loadingIndicator={this.state.loadingStatus === 'LOADING'} />
            </>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(TaxLienSearchResultsDetails);