import React, { Component } from "react"
import { Container, Col, Table, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import SearchResultsDetailsNoResults from './SearchResultsDetailsNoResults'
import { searchByTorrensCertificate, selectSearchSequenceRow  } from "../../../redux/actions/searchActions";
import Loading from '../../common/modal/Loading'
import { LOADING_STATUES, SEARCH_RESULTS_SOURCE } from "../../constants/constants";
import Thumbnails from "../../image/Thumbnails";

const mapStateToProps = state => {
    return {
        isTorrensUseFileNumbers : state.landlinkConfigReducer.torrensUseFileNumber,
        searchResults: state.searchResultsReducer,
        searchResultsDetails: state.searchDetailsTorrensCertificateReducer,
        currentUser: state.currentUserReducer,
        currentSearch: state.searchHistoryReducer.searchHistoryList[0],
        currentSort: state.searchResultsSortReducer,
        currentPageNumber: state.searchResultsPaginationReducer,
        searchSequence: state.searchSequenceStartedReducer,
        searchSequenceSelectedDocumentId: state.searchResultsTableSelectedRowReducer       
    };
}

const matchDispatchToProps = (dispatch) => {
    return {
        searchByTorrensCertificate: (searchCriteria, history, callback) => dispatch(searchByTorrensCertificate(searchCriteria, history, callback)),
        startSearchSequence: (currentSearch) => dispatch({ type: 'START_SEARCH_SEQUENCE', search: currentSearch}),
        selectSearchSequenceRow: (id) =>  dispatch(selectSearchSequenceRow(id))
    }
}

class TorrensSearchResultsDetails extends Component {
   
    constructor(props) {
        super(props);

        this.state = {
            imagingWindows: this.createImagingWindowsWindowState('', false),
            loadingStatus: ""
        };
    }

    showLoading = () => {
        if (this.state.loadingStatus === "") {
            this.setState({
                loadingStatus: LOADING_STATUES.LOADING
            })
            return true;
        }
        return false;
    }

    hideLoading = (message, status) => {
        if (status === 'ERROR') {
            alert(message);
        }
        this.setState({
            loadingStatus: ""
        })
    }

    handleClick = (event) => {
        event.preventDefault();
        
        this.setState({
            loadingStatus: LOADING_STATUES.LOADING
        })
        
        if (!this.props.searchSequence) {
            let currentSearchCriteria = this.props.currentSearch;
            currentSearchCriteria.pageNumber = this.props.currentPageNumber;
            currentSearchCriteria.sortKey = this.props.currentSort.sortKey;
            currentSearchCriteria.sortDirection = this.props.currentSort.sortDirection;
            this.props.startSearchSequence(currentSearchCriteria); 
            this.props.selectSearchSequenceRow(this.props.searchResultsDetails);
        }

        let searchCriteria = {
            certificateNumber: event.target.getAttribute("data-certificatenumber")
        }

        this.props.searchByTorrensCertificate(searchCriteria, this.props.history, this.hideLoading);
    }  

        // May return null if not found.
        getSelectedSearchResult = () => {
            if (!this.props.searchResults || (this.props.searchResultsDetails === 0)) {
                return null;
            }
    
            let searchResult = this.props.searchResults.searchResultList.find((searchResult) => {
                return this.props.searchResultsDetails == searchResult.id;
            });
    
            return searchResult;
        }
    
        // Can return null.
        getSelectedSearchResultInfo = () => {
            let searchResult = this.getSelectedSearchResult();
    
            if (!searchResult) {
                return null;
            }
    
            let info = { 
                documentNumber: searchResult.docNumber ? searchResult.docNumber : searchResult.certificateNumber,
                instrumentCode: '',      // there is none for this doc type
                source : this.props.searchResults.source
            };
    
            if (searchResult.docType) {
                info.documentType = searchResult.docType;
            } else if (info.source === SEARCH_RESULTS_SOURCE.LANDLINK) {
                info.documentType = '1';
            } else if (info.source === SEARCH_RESULTS_SOURCE.IMAGING_SYSTEM) {
                // Imaging system.
                info.documentType = '2';
            } else {
                // Should really cause some exception.
                info.documentType = '?';
            }
            
            info.documentKey = info.documentType + info.documentNumber;

            return info;
        }
    
        handleImageClick = (event) => {
            if (event) {
                event.preventDefault();
             }
               
            let info = this.getSelectedSearchResultInfo();
    
            if (!info) {
                alert("Select a certificate.");
                return; 
            }
    
            this.setState( {
                imagingWindows: this.createImagingWindowsWindowState(info, true)
            } );
        }
    
        handleImageComplete = () => {
            this.setState( {
                imagingWindows: this.createImagingWindowsWindowState(null, false)
            } );
        }
    
        createImagingWindowsWindowState = (searchResult, show) => {
            return {
                docKey: searchResult ? searchResult.documentKey : '',
                instCode: searchResult ? searchResult.instrumentCode : '',
                show: show,
                source: searchResult ? searchResult.source : ''
            }
        }
        
    
    render() {
        let selectedSearchResult = this.getSelectedSearchResult();

        if (!selectedSearchResult) {
            return (<SearchResultsDetailsNoResults />)
        }

        let info = this.getSelectedSearchResultInfo();
        let { certificateNumber, fileNumber, displayBookAndPage, certifiedDate, shortStatus, cancelledBySearchResults } = selectedSearchResult;

        return (
            <>
            <Col style={{ paddingLeft: 0, marginRight: 0, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} >
                <Container key={this.props.searchResultsDetails} style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 0, paddingBottom: 10, marginRight: 0 }}  fluid="true">      
                    <div>
                        <h5>
                            <strong>Torrens&nbsp;&nbsp;</strong> 
                            <span className="badge badge-secondary">{shortStatus}</span>
                            {
                                this.props.currentUser.certificateRetrieval && 
                                <div style={{ float: 'right' }}>
                                    <Button variant="primary" size="sm" onClick={this.handleImageClick} >Certificate</Button>
                                </div>
                            }
                        </h5>
                    </div>
                    <hr style={{background: 'black'}}></hr>
                    <div style={{marginBottom: 0, marginTop: 10}}>
                        <div><strong>Certificate #: </strong>{certificateNumber}</div>
                        {   
                            this.props.isTorrensUseFileNumbers === true &&
                            <div><strong>File #: </strong>{fileNumber}</div>
                        }
                        <div><strong>Certified Date: </strong>{certifiedDate}</div>
                        <div><strong>Book/Page: </strong>{displayBookAndPage}</div>
                        
                        {
                            shortStatus === 'Cancelled' &&
                            <Table striped bordered hover variant="dark"  size="sm">  
                                <thead><tr><th scope="col">Cancelled By</th></tr></thead>
                                <tbody>
                                    {
                                        cancelledBySearchResults.map(cancelledBySearchResult => {
                                            return <tr id={cancelledBySearchResult.cancelledByCertificateId}><td><a href='#BogusToRemoveWarning' name="searchByTorrensCertificate" data-certificatenumber={cancelledBySearchResult.cancelledByCertificateNumber} onClick={this.handleClick} style={{ textDecoration: 'none' }} > {cancelledBySearchResult.cancelledByCertificateNumber}</a></td></tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        }

                    </div>
                </Container>
            </Col>
            <Loading loadingIndicator={this.state.loadingStatus === 'LOADING'} />
            <Thumbnails show={this.state.imagingWindows.show} 
                    docKey={info.documentKey} 
                    instCode={this.state.imagingWindows.instCode} 
                    complete={this.handleImageComplete} />
            </>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(TorrensSearchResultsDetails);