import React from 'react';
import axios from "axios";
import configData from "../../config/config.json";
import { connect } from "react-redux";
import FormTemplate from "../common/form/FormTemplate.js";
import Alert from '../common/modal/Alert.js';
import { setSelectedControlPanelType, AccountsControlPanelKey } from "../../redux/actions/controlPanelActions.js";
import { saveAccount } from "../../redux/actions/accountActions.js";
import Loading from '../common/modal/Loading.js';
import Confirmation from '../common/modal/Confirmation.js';
import { LOADING_STATUES } from "../constants/constants.js";

const componentName = AccountsControlPanelKey;

const mapStateToProps = (state) => {
	return {
		accountTypes: state.accountTypesReducer.codeDescriptionResultList,
		accountListFilters: state.accountListFiltersReducer,
		countyConfigFlatFee: state.countyConfigFlatFeeReducer,
		role: state.currentUserReducer.role,
		countyConfigBillingSchedule: state.countyConfigBillingScheduleReducer
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
	}
}

class AccountTasks extends React.Component {
	state = {
		id: 0,
		accountType: 'A_LA_CARTE',
		lastBilled: '',
		lastImageRenewal: '',
		lastTasks: '',
		needsBilling: false,
		needsImageRenewal: false, 
		showPrepareToRerunAccountTasksConfirmationDialog: false,
		errors: {},
		showAlert: false,
		alertMessage: "",
		redirect: false,
		status: ''
	  }

	handleBilledNow = (event) => {
		event.preventDefault();

		if ((this.state.status === "") || (this.state.status === "SUCCESS")) {
			this.setState({
			  status: LOADING_STATUES.LOADING
			})
		} else {
			return;
		}
			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountTasksBilledNow", this.state, { withCredentials: true })
				.then((response) => {
					const content = response.data.content;
					let newState = {
						...this.getStateFromContent(content),
						status: "",
						redirect: false
					};

					this.setState(newState, this.showAlert(response.data.message));
				}, (error) => {
					this.setState({status: "", redirect: false}, this.showAlert(error.message));
				});
	}

	handleImageRenewNow = (event) => {
		event.preventDefault();

		if ((this.state.status === "") || (this.state.status === "SUCCESS")) {
			this.setState({
			  status: LOADING_STATUES.LOADING
			})
		} else {
			return;
		}

			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountTasksImageRenewedNow", this.state, { withCredentials: true })
				.then((response) => {
					const content = response.data.content;
					let newState = {
						...this.getStateFromContent(content),
						status: "",
						redirect: false
					};

					this.setState(newState, this.showAlert(response.data.message));
				}, (error) => {
					this.setState({status: "", redirect: false}, this.showAlert(error.message));
				});
	}

	handleChange = (event) => {
		event.preventDefault();

		let value = event.target.value;
		 
		this.setState({
			[event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value),
			errors: {},
			status: ''
		})

	}
	
	getAccountId = () => {
		return { accountId: this.state.id };
	}

	handleSubmit = (event) => {
		event.preventDefault();

		if (this.state.status === "") {
			this.setState({
			  status: LOADING_STATUES.LOADING
			})
		} else {
			return;
		}
		
			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountTasks", this.state, { withCredentials: true })
				.then((response) => {
					const content = response.data.content;
					let newState = {
						...this.getStateFromContent(content),
						status: "SUCCESS",
						redirect: false
					};

					this.setState(newState);
				}, (error) => {
					this.setState({status: "", redirect: false}, this.showAlert(error.message));
				});
	}

	handleCancel = (event) => {
		event.preventDefault();
		this.props.history.push('/controlPanel/accounts');
	}

	showLoading = () => {
		if ((this.state.status === "") || (this.state.status === "SUCCESS")) {
			this.setState({
				status: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	hideLoading = (loadingStatus) => {
		if (loadingStatus === 'ERROR') {
			this.setState({
				status: ""
			})
		} else {
			this.setState({
				status: ""
			})
		}
	}

	showPrepareToRerunAccountTasksConfirmation = (event) => {
		event.preventDefault();
		this.setState({
			showPrepareToRerunAccountTasksConfirmationDialog: true
		})
	}

	hidePrepareToRerunAccountTasksConfirmation = () => {
		this.setState({
			showPrepareToRerunAccountTasksConfirmationDialog: false
		})
	}

	handleRunAccountTasks = (event) => {
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/runAccountTasks", this.getAccountId(), { withCredentials: true })
				.then((response) => {
					const content = response.data.content;
					let newState = {
						...this.getStateFromContent(content),
						status: ""
					};

					this.setState(newState, this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ status: ""}, this.showAlert(error.response.data.message));
				});
		}
	  }
	
	  handlePrepareToRerunAccountTasks = (event) => {
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/prepareToRerunAccountTasks", this.getAccountId(), { withCredentials: true })
				.then((response) => {
					const content = response.data.content;
					let newState = {
						...this.getStateFromContent(content),
						status: ""
					};

					this.setState(newState, this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ status: ""}, this.showAlert(error.response.data.message));
				});
		}
	  }
	
	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: '',
			alertType: ''
		})
	
		if (this.state.redirect) {
			this.props.history.push('/controlPanel/accounts');
		}
	}

	componentDidMount() {
		axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/accountTasks/" + this.props.match.params.id, { withCredentials: true })
		.then(
			(response) => {
				const content = response.data.content;
				let newState = this.getStateFromContent(content);

				this.setState(newState);
			},
			(error) => {
				console.log(error);
			}
		);
	}

	getStateFromContent = (content) => {
		return {
			accountType: content.accountType,
			id: content.id,
			lastBilled: content.lastBilled,
			lastImageRenewal: content.lastImageRenewal,
			lastTasks: content.lastTasks,
			needsBilling: content.needsBilling,
			needsImageRenewal: content.needsImageRenewal,
			status: ''
		};
	}

	render() {
		let formGroupRows = [];

		formGroupRows.push(
			{
				formGroups: [
					{ id: 'lastBilled', label: 'Last Billed', fieldType: 'text', value: this.state.lastBilled, required: false, error: this.state.errors['lastBilled'] },
					{ id: 'needsBilling', label: 'Needs Billing', fieldType: 'select', value: this.state.needsBilling, required: true },
					{ id: 'billedNow', label: 'Indicate Billed Now', fieldType: 'button', onClick: this.handleBilledNow, hideField: false }
				]
			},
			{
				formGroups: [
					{ id: 'lastImageRenewal', label: 'Last Image Renewal', fieldType: 'text', value: this.state.lastImageRenewal, required: false, error: this.state.errors['lastImageRenewal'] },
					{ id: 'needsImageRenewal', label: 'Needs Image Renewal', fieldType: 'select', value: this.state.needsImageRenewal, required: true },
					{ id: 'imageRenewNow', label: 'Indicate Renewed Now', fieldType: 'button', onClick: this.handleImageRenewNow, hideField: false }
				]
			},
			{
				formGroups: [
					{ id: 'lastTasks', label: 'Last Tasks', fieldType: 'text', value: this.state.lastTasks, required: false, error: this.state.errors['lastTasks'] }
				]
			}
		)

		let secondaryButtons = [];

		if (this.props.role === 'TRIMIN') {
			secondaryButtons.push( {label:'Prepare to Rerun Account Tasks', onClick: this.showPrepareToRerunAccountTasksConfirmation, className: 'btn btn-secondary btn-sm'} );
		}
	
		secondaryButtons.push( {label:'Run Account Tasks', onClick: this.handleRunAccountTasks, className: 'btn btn-secondary btn-sm'} );

		const formProps = {
			componentName: "AccountTasks",
			pageHeading: "Account Tasks - " + this.props.match.params.name,
			onChange: this.handleChange,
			onSubmit: this.handleSubmit,
			onCancel: this.handleCancel,
			formGroupRows: formGroupRows,
			successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
			loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
			secondaryButtons: secondaryButtons
		}

		return (
			<>
				<FormTemplate {...formProps} />
				<Confirmation show={this.state.showPrepareToRerunAccountTasksConfirmationDialog} cancelConfirmation={this.hidePrepareToRerunAccountTasksConfirmation} proceedConfirmation={this.handlePrepareToRerunAccountTasks}  question={"Prepare to rerun account tasks?"} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType} />
			</>
		)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountTasks);
