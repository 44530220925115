import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import SideNavigationLabel from "../side/SideNavigationLabel";
import SideNavigationToggle from "../side/SideNavigationToggle";
import SideNavigationNavLink from "../side/SideNavigationNavLink";

import Accounts from "./Accounts";
import AccountEntry from "./AccountEntry";
import AccountReload from "./AccountReload";
import AccountPageAccess from "./AccountPageAccess";
import AccountTasks from "./AccountTasks";
import CreditCardAlternateRates from "./CreditCardAlternateRates";
import CreditCardExtra from "./CreditCardExtra";
import Escrow from "./Escrow";
import FlatFeeRates from "./FlatFeeRates";
import ALaCarteRates from "./ALaCarteRates";
import ALaCarteRatesSpecial from "./ALaCarteRatesSpecial";

import Common from "./Common";

import Efile from "./Efile";
import Languages from "./Languages";
import HomeLatestNews from './HomeLatestNews';
import HomeExternalLinks from './HomeExternalLinks';
import HomeNotableDates from "./HomeNotableDates";
import HomeSelections from './HomeSelections';
import LoginPageLinks from './LoginPageLinks';
import LoginPageLatestNews from './LoginPageLatestNews';
import LoginPageSlides from './LoginPageSlides';
import LoginPageRegistration from './LoginPageRegistration';
import Search from "./Search";
import Search2 from "./Search2";
import Reports from "./Reports";
import PerformanceMonitoring from "./PerformanceMonitoring";
import ReloadConfiguration from "./ReloadConfiguration";
import LandSharkOneAccountsUsers from "./LandSharkOneAccountsUsers";
import LandSharkOneAuditLog from "./LandSharkOneAuditLog";
import UserLockout from "./UserLockout";
import UserLockout2 from "./UserLockout2";
import UserPurgeSchedule from "./UserPurgeSchedule";
import UserPurgeNow from "./UserPurgeNow";
import UserAccess from "./UserAccess";
import BillingSchedule from './BillingSchedule';
import FlatFeeOverride from './FlatFeeOverride';
import AuditLogPurgeSchedule from "./AuditLogPurgeSchedule";

import CommonTop from "../../components/top/CommonTop";

import { AccountsControlPanelKey, FlatFeeControlPanelKey, CreditCardAlternateRatesControlPanelKey, CreditCardExtraControlPanelKey, FlatFeeOverrideControlPanelKey, 
	ALaCarteRatesControlPanelKey, ALaCarteRatesSpecialControlPanelKey, BillingScheduleControlPanelKey, 
	HomeSelectionsControlPanelKey, HomeNotableDatesControlPanelKey, HomeLatestNewsControlPanelKey, HomeExternalLinksControlPanelKey,
	SearchControlPanelKey, Search2ControlPanelKey, ReportsControlPanelKey, CommonControlPanelKey, EfileControlPanelKey, EscrowControlPanelKey, LanguagesControlPanelKey, PerformanceMonitoringControlPanelKey,
	ReloadConfigurationControlPanelKey,
	UserAccessControlPanelKey, UserPurgeScheduleControlPanelKey, UserPurgeNowControlPanelKey, UserLockoutControlPanelKey, UserLockout2ControlPanelKey, LoginPageLatestNewsControlPanelKey, LoginPageLinksControlPanelKey, LoginPageSlidesControlPanelKey, LoginPageRegistrationControlPanelKey,
	AuditLogPurgeScheduleControlPanelKey, 
	LandSharkOneAccountsUsersControlPanelKey, LandSharkOneAuditLogControlPanelKey} from "../../redux/actions/controlPanelActions";

import { auditLogPurgeIcon, auditLogPurgeActiveIcon, loginIcon, loginActiveIcon, accountIcon, accountActiveIcon, accountIconBlack, flatFeeIcon, flatFeeActiveIcon, unitRatesIcon, unitRatesActiveIcon,
		 creditCardIcon2, creditCardActiveIcon2, escrowReloadIcon, escrowReloadActiveIcon, fileImportIcon, fileImportActiveIcon, languageIcon, languageActiveIcon,
	  	 userIconBlack, fileIcon, fileActiveIcon, fileCodeIcon, dateIcon, dateActiveIcon, dollarIconBlack,
		 searchIcon, searchActiveIcon, homeIcon, homeActiveIcon, commonIcon, commonActiveIcon,
		 userPurgeIcon, userPurgeActiveIcon, userClockIcon, userClockActiveIcon, userLockIcon, userLockActiveIcon, doorOpenIcon, doorOpenActiveIcon,
		 stethoscopeIcon, stethoscopeActiveIcon, cogIconBlack, arrowRightIcon, arrowRightActiveIcon } from "../../img/font_awesome/font_awesome"

const mapStateToProps = state => {
	return {
		selectedControlPanelType: state.controlPanelTypeReducer.selectedControlPanelType,
		role: state.currentUserReducer.role  
	};
}

class ControlPanel extends React.Component {

	render() {

		let accountsHeadingProps = {
			image: accountIconBlack,
			name: "Account Admin:"
		}

		let accountsProps = {
			id: "accountsControlPanelNavLink",
			link: "/controlPanel/Accounts",
			selected: this.props.selectedControlPanelType === AccountsControlPanelKey,
			image: this.props.selectedControlPanelType === AccountsControlPanelKey ? accountActiveIcon: accountIcon,
			name: "Accounts"
		}

		let feeStructureHeadingProps = {
			image: dollarIconBlack,
			name: "Fee Structure:"
		}

		let creditCardAlternateRatesProps = {
			id: "creditCardAlternateRatesControlPanelNavLink",
			link: "/controlPanel/CreditCardAlternateRates",
			selected: this.props.selectedControlPanelType === CreditCardAlternateRatesControlPanelKey,
			image: this.props.selectedControlPanelType === CreditCardAlternateRatesControlPanelKey ? creditCardActiveIcon2 : creditCardIcon2,
			name: "Credit Card Alternate Rates"
		}

		let creditCardExtraProps = {
			id: "creditCardExtraControlPanelNavLink",
			link: "/controlPanel/CreditCardExtra",
			selected: this.props.selectedControlPanelType === CreditCardExtraControlPanelKey,
			image: this.props.selectedControlPanelType === CreditCardExtraControlPanelKey ? creditCardActiveIcon2 : creditCardIcon2,
			name: "Credit Card Extra"
		}

		let escrowProps = {
			id: "escrowControlPanelNavLink",
			link: "/controlPanel/Escrow",
			selected: this.props.selectedControlPanelType === EscrowControlPanelKey,
			image: this.props.selectedControlPanelType === EscrowControlPanelKey ? escrowReloadActiveIcon : escrowReloadIcon,
			name: "Escrow"
		}

		let flatFeeProps = {
			id: "flatFeeControlPanelNavLink",
			link: "/controlPanel/FlatFeeRates",
			selected: this.props.selectedControlPanelType === FlatFeeControlPanelKey,
			image: this.props.selectedControlPanelType === FlatFeeControlPanelKey ? flatFeeActiveIcon : flatFeeIcon,
			name: "Flat Fee Rates"
		}

		let flatFeeOverrideProps = {
			id: "flatFeeOverrideControlPanelNavLink",
			link: "/controlPanel/FlatFeeOverride",
			selected: this.props.selectedControlPanelType === FlatFeeOverrideControlPanelKey,
			image: this.props.selectedControlPanelType === FlatFeeOverrideControlPanelKey ? flatFeeActiveIcon : flatFeeIcon,
			name: "Flat Fee Usage Override"
		}

		let aLaCarteRatesProps = {
			id: "aLaCarteRatesControlPanelNavLink",
			link: "/controlPanel/alacarterates",
			selected: this.props.selectedControlPanelType === ALaCarteRatesControlPanelKey,
			image: this.props.selectedControlPanelType === ALaCarteRatesControlPanelKey ? unitRatesActiveIcon : unitRatesIcon,
			name: "A La Carte Rates"
		}

		let aLaCarteRatesInstrumentCodeProps = {
			id: "aLaCarteRatesInstrumentCodeControlPanelNavLink",
			link: "/controlPanel/alacarteratesspecial",
			selected: this.props.selectedControlPanelType === ALaCarteRatesSpecialControlPanelKey,
			image: this.props.selectedControlPanelType === ALaCarteRatesSpecialControlPanelKey ? unitRatesActiveIcon : unitRatesIcon,
			name: "A La Carte Special Rates"
		}

		let billingScheduleProps = {
			id: "billingScheduleControlPanelNavLink",
			link: "/controlPanel/BillingSchedule",
			selected: this.props.selectedControlPanelType === BillingScheduleControlPanelKey,
			image: this.props.selectedControlPanelType === BillingScheduleControlPanelKey ? dateActiveIcon : dateIcon,
			name: "Schedule"
		}

		let webPagesProps = {
			image: fileCodeIcon,
			name: "Web Pages:"
		}

		let commonProps = {
			id: "commonControlPanelNavLink",
			link: "/controlPanel/Common",
			selected: this.props.selectedControlPanelType === CommonControlPanelKey,
			image: this.props.selectedControlPanelType === CommonControlPanelKey ? commonActiveIcon : commonIcon,
			name: "Common"
		}

		let efileProps = {
			id: "efileControlPanelNavLink",
			link: "/controlPanel/Efile",
			selected: this.props.selectedControlPanelType === EfileControlPanelKey,
			image: this.props.selectedControlPanelType === EfileControlPanelKey ? fileImportActiveIcon : fileImportIcon,
			name: "E File"
		}

		let languagesProps = {
			id: "languagesControlPanelNavLink",
			link: "/controlPanel/Languages",
			selected: this.props.selectedControlPanelType === LanguagesControlPanelKey,
			image: this.props.selectedControlPanelType === LanguagesControlPanelKey ? languageActiveIcon : languageIcon,
			name: "Languages"
		}

		let homeLatestNewsProps = {
			id: "homeLatestNewsControlPanelNavLink",
			link: "/controlPanel/HomeLatestNews",
			selected: this.props.selectedControlPanelType === HomeLatestNewsControlPanelKey,
			image: this.props.selectedControlPanelType === HomeLatestNewsControlPanelKey ? homeActiveIcon : homeIcon,
			name: "Home - More News"
		}

		let homeMoreLinksProps = {
			id: "homeExternalLinksControlPanelNavLink",
			link: "/controlPanel/HomeExternalLinks",
			selected: this.props.selectedControlPanelType === HomeExternalLinksControlPanelKey,
			image: this.props.selectedControlPanelType === HomeExternalLinksControlPanelKey ? homeActiveIcon : homeIcon,
			name: "Home - More Links"
		}

		let homeNotableDatesProps = {
			id: "homeSelectionsControlPanelNavLink",
			link: "/controlPanel/HomeNotableDates",
			selected: this.props.selectedControlPanelType === HomeNotableDatesControlPanelKey,
			image: this.props.selectedControlPanelType === HomeNotableDatesControlPanelKey ? homeActiveIcon : homeIcon,
			name: "Home - Notable Dates"
		}

		let homeSelectionsProps = {
			id: "homeSelectionsControlPanelNavLink",
			link: "/controlPanel/HomeSelections",
			selected: this.props.selectedControlPanelType === HomeSelectionsControlPanelKey,
			image: this.props.selectedControlPanelType === HomeSelectionsControlPanelKey ? homeActiveIcon : homeIcon,
			name: "Home - Selections"
		}

		let loginPageLinksProps = {
			id: "loginPageLinksControlPanelNavLink",
			link: "/controlPanel/LoginPageLinks",
			selected: this.props.selectedControlPanelType === LoginPageLinksControlPanelKey,
			image: this.props.selectedControlPanelType === LoginPageLinksControlPanelKey ? loginActiveIcon : loginIcon,
			name: "Login - Links"
		}

		let loginPageLatestNewsProps = {
			id: "loginPageLatestNewsControlPanelNavLink",
			link: "/controlPanel/LoginPageLatestNews",
			selected: this.props.selectedControlPanelType === LoginPageLatestNewsControlPanelKey,
			image: this.props.selectedControlPanelType === LoginPageLatestNewsControlPanelKey ? loginActiveIcon : loginIcon,
			name: "Login - Latest News"
		}

		let loginPageSlidesProps = {
			id: "loginPageSlidesControlPanelNavLink",
			link: "/controlPanel/LoginPageSlides",
			selected: this.props.selectedControlPanelType === LoginPageSlidesControlPanelKey,
			image: this.props.selectedControlPanelType === LoginPageSlidesControlPanelKey ? loginActiveIcon : loginIcon,
			name: "Login - Slides"
		}

		let loginPageRegistrationProps = {
			id: "loginRegistrationControlPanelNavLink",
			link: "/controlPanel/LoginPageRegistration",
			selected: this.props.selectedControlPanelType === LoginPageRegistrationControlPanelKey,
			image: this.props.selectedControlPanelType === LoginPageRegistrationControlPanelKey ? loginActiveIcon : loginIcon,
			name: "Login - Registration"
		}

		let searchProps = {
			id: "searchControlPanelNavLink",
			link: "/controlPanel/Search",
			selected: this.props.selectedControlPanelType === SearchControlPanelKey,
			image: this.props.selectedControlPanelType === SearchControlPanelKey ? searchActiveIcon : searchIcon,
			name: "Search 1"
		}

		let search2Props = {
			id: "search2ControlPanelNavLink",
			link: "/controlPanel/Search2",
			selected: this.props.selectedControlPanelType === Search2ControlPanelKey,
			image: this.props.selectedControlPanelType === Search2ControlPanelKey ? searchActiveIcon : searchIcon,
			name: "Search 2"
		}

		let reportsProps = {
			id: "reportsControlPanelNavLink",
			link: "/controlPanel/Reports",
			selected: this.props.selectedControlPanelType === ReportsControlPanelKey,
			image: this.props.selectedControlPanelType === ReportsControlPanelKey ? fileActiveIcon : fileIcon,
			name: "Reports"
		}
		
		let technicalProps = {
			image: cogIconBlack,
			name: "Technical:"
		}

		let performanceMonitoringProps = {
			id: "performanceMonitoringControlPanelNavLink",
			link: "/controlPanel/PerformanceMonitoring",
			selected: this.props.selectedControlPanelType === PerformanceMonitoringControlPanelKey,
			image: this.props.selectedControlPanelType === PerformanceMonitoringControlPanelKey ? stethoscopeActiveIcon : stethoscopeIcon,
			name: "Performance Monitoring"
		}

		let reloadConfigurationProps = {
			id: "reloadConfigurationControlPanelNavLink",
			link: "/controlPanel/ReloadConfiguration",
			selected: this.props.selectedControlPanelType === ReloadConfigurationControlPanelKey,
			image: this.props.selectedControlPanelType === ReloadConfigurationControlPanelKey ? stethoscopeActiveIcon : stethoscopeIcon,
			name: "Reload Configuration"
		}

		let landSharkOneAccountsUsersProps = {
			id: "landSharkOneAccountsUsersControlPanelNavLink",
			link: "/controlPanel/LandSharkOneAccountsUsers",
			selected: this.props.selectedControlPanelType === LandSharkOneAccountsUsersControlPanelKey,
			image: this.props.selectedControlPanelType === LandSharkOneAccountsUsersControlPanelKey ? arrowRightActiveIcon : arrowRightIcon,
			name: "LS1 Accounts & Users"
		}

		let landSharkOneAuditLogProps = {
			id: "landSharkOneAuditLogControlPanelNavLink",
			link: "/controlPanel/LandSharkOneAuditLog",
			selected: this.props.selectedControlPanelType === LandSharkOneAuditLogControlPanelKey,
			image: this.props.selectedControlPanelType === LandSharkOneAuditLogControlPanelKey ? arrowRightActiveIcon : arrowRightIcon,
			name: "LS1 Audit Log"
		}

		let userAdminProps = {
			image: userIconBlack,
			name: "User Admin:"
		}

		let userAccessProps = {
			id: "userAccessControlPanelNavLink",
			link: "/controlPanel/UserAccess",
			selected: this.props.selectedControlPanelType === UserAccessControlPanelKey,
			image: this.props.selectedControlPanelType === UserAccessControlPanelKey ? doorOpenActiveIcon : doorOpenIcon,
			name: "Access"
		}

		let userLockoutProps = {
			id: "userLockoutControlPanelNavLink",
			link: "/controlPanel/UserLockout",
			selected: this.props.selectedControlPanelType === UserLockoutControlPanelKey,
			image: this.props.selectedControlPanelType === UserLockoutControlPanelKey ? userLockActiveIcon : userLockIcon,
			name: "Lockout 1"
		}

		let userLockout2Props = {
			id: "userLockout2ControlPanelNavLink",
			link: "/controlPanel/UserLockout2",
			selected: this.props.selectedControlPanelType === UserLockout2ControlPanelKey,
			image: this.props.selectedControlPanelType === UserLockout2ControlPanelKey ? userLockActiveIcon : userLockIcon,
			name: "Lockout 2"
		}

		let userPurgeNowProps = {
			id: "userPurgeNowControlPanelNavLink",
			link: "/controlPanel/UserPurgeNow",
			selected: this.props.selectedControlPanelType === UserPurgeNowControlPanelKey,
			image: this.props.selectedControlPanelType === UserPurgeNowControlPanelKey ? userPurgeActiveIcon : userPurgeIcon,
			name: "Purge Now"
		}

		let userPurgeScheduleProps = {
			id: "userPurgeNowControlPanelNavLink",
			link: "/controlPanel/UserPurgeSchedule",
			selected: this.props.selectedControlPanelType === UserPurgeScheduleControlPanelKey,
			image: this.props.selectedControlPanelType === UserPurgeScheduleControlPanelKey ? userClockActiveIcon : userClockIcon,
			name: "Purge Schedule"
		}

		let auditLogPurgeScheduleProps = {
			id: "auditLogPurgeScheduleControlPanelNavLink",
			link: "/controlPanel/AuditLogPurgeSchedule",
			selected: this.props.selectedControlPanelType === AuditLogPurgeScheduleControlPanelKey,
			image: this.props.selectedControlPanelType === AuditLogPurgeScheduleControlPanelKey ? auditLogPurgeActiveIcon : auditLogPurgeIcon,
			name: "Audit Log Purge"
		}

		let defaultRoute = <Route path="/controlPanel" component={HomeNotableDates} />;

		if (ALaCarteRatesControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={ALaCarteRates} />;
		} else if (ALaCarteRatesSpecialControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={ALaCarteRatesSpecial} />;
		} else if (CreditCardAlternateRatesControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={CreditCardAlternateRates} />;
		} else if (CreditCardExtraControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={CreditCardExtra} />;
		} else if (EscrowControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={Escrow} />;
		} else if (FlatFeeControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={FlatFeeRates} />;
		} else if (FlatFeeOverrideControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={FlatFeeOverride} />;
		} else if (AccountsControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={Accounts} />;
		} else if (AuditLogPurgeScheduleControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={AuditLogPurgeSchedule} />;
		} else if (UserAccessControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={UserAccess} />;
		} else if (UserPurgeNowControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={UserPurgeNow} />;
		} else if (UserPurgeScheduleControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={UserPurgeSchedule} />;
		} else if (CommonControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={Common} />;
		} else if (EfileControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={Efile} />;
		} else if (LanguagesControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={Languages} />;
		} else if (LoginPageLinksControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={LoginPageLinks} />;
		} else if (LoginPageLatestNewsControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={LoginPageLatestNews} />;
		} else if (LoginPageSlidesControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={LoginPageSlides} />;
		} else if (LoginPageRegistrationControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={LoginPageRegistration} />;
		} else if (HomeLatestNewsControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={HomeLatestNews} />;
		} else if (HomeExternalLinksControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={HomeExternalLinks} />;
		} else if (HomeSelectionsControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={HomeSelections} />;
		} else if (SearchControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={Search} />;
		} else if (Search2ControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={Search2} />;
		} else if (ReportsControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={Reports} />;
		} else if (UserLockoutControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={UserLockout} />;
		} else if (UserLockout2ControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={UserLockout2} />;
		} else if (PerformanceMonitoringControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={PerformanceMonitoring} />;
		} else if (ReloadConfigurationControlPanelKey === this.props.selectedControlPanelType) {
			defaultRoute = <Route path="/controlPanel" component={ReloadConfiguration} />;
		}

		

		
		let technicalNavLink = '';
		if (this.props.role === 'TRIMIN') {
			technicalNavLink = <><Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...technicalProps} /></Nav.Link>
			<SideNavigationNavLink {...efileProps} />
			<SideNavigationNavLink {...landSharkOneAccountsUsersProps} />
			<SideNavigationNavLink {...landSharkOneAuditLogProps} />
			<SideNavigationNavLink {...languagesProps} />
			<SideNavigationNavLink {...performanceMonitoringProps} /> 
			<SideNavigationNavLink {...reloadConfigurationProps} />
			</>
		}

		return (
			<div>
				<CommonTop history={this.props.history} />
				<div style={{ float: "left", minWidth: 38, maxWidth: 280 }}>
					<SideNavigationToggle />
					<Navbar bg="light" style={{ padding: 0, display: "block" }}>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Nav className="flex-column" style={{ width: '100%', float: "left", marginLeft: 2 }}>
							<Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...feeStructureHeadingProps} /></Nav.Link>
								<SideNavigationNavLink {...aLaCarteRatesProps} />
								<SideNavigationNavLink {...aLaCarteRatesInstrumentCodeProps} />
								<SideNavigationNavLink {...creditCardAlternateRatesProps} />
								<SideNavigationNavLink {...creditCardExtraProps} />
								<SideNavigationNavLink {...escrowProps} />
								<SideNavigationNavLink {...flatFeeProps} />
								<SideNavigationNavLink {...flatFeeOverrideProps} />
								<SideNavigationNavLink {...billingScheduleProps} />
							<Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...accountsHeadingProps} /></Nav.Link>
								<SideNavigationNavLink {...accountsProps} />
								<SideNavigationNavLink {...auditLogPurgeScheduleProps} />
							<Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...userAdminProps} /></Nav.Link>
								<SideNavigationNavLink {...userAccessProps} />
								<SideNavigationNavLink {...userLockoutProps} />
								<SideNavigationNavLink {...userLockout2Props} />
								<SideNavigationNavLink {...userPurgeNowProps} />	
								<SideNavigationNavLink {...userPurgeScheduleProps} />	
							<Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...webPagesProps} /></Nav.Link>
								<SideNavigationNavLink {...commonProps} />
								<SideNavigationNavLink {...loginPageLinksProps} />
								<SideNavigationNavLink {...loginPageLatestNewsProps} />
								<SideNavigationNavLink {...loginPageRegistrationProps} />
								<SideNavigationNavLink {...loginPageSlidesProps} />
								<SideNavigationNavLink {...homeMoreLinksProps} />
								<SideNavigationNavLink {...homeLatestNewsProps} />
								<SideNavigationNavLink {...homeNotableDatesProps} />
								<SideNavigationNavLink {...homeSelectionsProps} />
								<SideNavigationNavLink {...searchProps} />
								<SideNavigationNavLink {...search2Props} />
								<SideNavigationNavLink {...reportsProps} />	
							{technicalNavLink}							
						</Nav>
					</Navbar>
				</div>
				<div style={{paddingBottom: 4}}>
					<Switch>
						<Route path="/controlPanel/accountPageAccess/:id/:name" component={AccountPageAccess} />
						<Route path="/controlPanel/accountReload/:id/:name" component={AccountReload} />
						<Route path="/controlPanel/accountTasks/:id/:name" component={AccountTasks} />
						<Route path="/controlPanel/accounts/:id" component={AccountEntry} />
						<Route path="/controlPanel/accounts" component={Accounts} />
						<Route path="/controlPanel/creditCardExtra" component={CreditCardExtra} />
						<Route path="/controlPanel/creditCardAlternateRates" component={CreditCardAlternateRates} />
						<Route path="/controlPanel/escrow" component={Escrow} />
						<Route path="/controlPanel/billingSchedule" component={BillingSchedule} />
						<Route path="/controlPanel/flatFeeOverride" component={FlatFeeOverride} />
						<Route path="/controlPanel/flatFeeRates" component={FlatFeeRates} />
						<Route path="/controlPanel/alacarteratesspecial" component={ALaCarteRatesSpecial} />
						<Route path="/controlPanel/alacarterates" component={ALaCarteRates} />
						<Route path="/controlPanel/common" component={Common} />
						<Route path="/controlPanel/efile" component={Efile} />
						<Route path="/controlPanel/homeLatestNews" component={HomeLatestNews} />
						<Route path="/controlPanel/homeExternalLinks" component={HomeExternalLinks} />
						<Route path="/controlPanel/homeNotableDates" component={HomeNotableDates} />
						<Route path="/controlPanel/homeSelections" component={HomeSelections} />
						<Route path="/controlPanel/languages" component={Languages} />
						<Route path="/controlPanel/loginPageLinks" component={LoginPageLinks} />
						<Route path="/controlPanel/loginPageLatestNews" component={LoginPageLatestNews} />
						<Route path="/controlPanel/loginPageSlides" component={LoginPageSlides} />
						<Route path="/controlPanel/loginPageRegistration" component={LoginPageRegistration} />
						<Route path="/controlPanel/search2" component={Search2} />
						<Route path="/controlPanel/search" component={Search} />
						<Route path="/controlPanel/reports" component={Reports} />
						<Route path="/controlPanel/performanceMonitoring" component={PerformanceMonitoring} />
						<Route path="/controlPanel/reloadConfiguration" component={ReloadConfiguration} />
						<Route path="/controlPanel/LandSharkOneAccountsUsers" component={LandSharkOneAccountsUsers} />
						<Route path="/controlPanel/LandSharkOneAuditLog" component={LandSharkOneAuditLog} />
						<Route path="/controlPanel/userAccess" component={UserAccess} />
						<Route path="/controlPanel/userLockout2" component={UserLockout2} />
						<Route path="/controlPanel/userLockout" component={UserLockout} />
						<Route path="/controlPanel/userPurgeNow" component={UserPurgeNow} />
						<Route path="/controlPanel/userPurgeSchedule" component={UserPurgeSchedule} />
						<Route path="/controlPanel/auditLogPurgeSchedule" component={AuditLogPurgeSchedule} />
						{defaultRoute}
					</Switch>
				</div>
			</div>	
		);
	}
}

export default connect(mapStateToProps)(ControlPanel);