import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import FormTemplate from "../common/form/FormTemplate";
import AccountSelectionList   from "../common/selection/AccountSelectionList";
import UserSelectionList   from "../common/selection/UserSelectionList";
import { reportStatement, exportStatement, setSelectedReportType, ReportStatementKey } from "../../redux/actions/reportActions";
import { amPmOptions, hourOptions, minuteOptions, validateDateTimes, validateYearRangeLimit } from "../../utils/DateValidation";
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = ReportStatementKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedReportType: () => dispatch(setSelectedReportType(componentName)),
    reportStatement: (searchCriteria, history, callback) => dispatch(reportStatement(searchCriteria, history, callback)),
    exportStatement: (searchCriteria, history, callback) => dispatch(exportStatement(searchCriteria, history, callback)),
    resetReportCriteria: () => dispatch({ type: 'RESET_REPORT_CRITERIA', componentName })
  }
}

const mapStateToProps = (state) => {
  let reportByCriteria = state.reportCriteriaReducer[componentName];

  let accountName = reportByCriteria['accountName'] ? reportByCriteria['accountName'] : "";
  let username = reportByCriteria['username'] ? reportByCriteria['username'] : "";
  let statementNumber = reportByCriteria['statementNumber'] ? reportByCriteria['statementNumber'] : "";
  let dataSource = reportByCriteria['dataSource'] ? reportByCriteria['dataSource'] : "FIVE";

  let fromDate = reportByCriteria['fromDate'] ? reportByCriteria['fromDate'] : moment().format('L');
  let toDate = reportByCriteria['toDate'] ? reportByCriteria['toDate'] : moment().format('L');
  let fromHour = reportByCriteria['fromHour'] ? reportByCriteria['fromHour'] : '12';
  let fromMinute = reportByCriteria['fromMinute'] ? reportByCriteria['fromMinute'] : '0';
  let fromAmPm = reportByCriteria['fromAmPm'] ? reportByCriteria['fromAmPm'] : 'am';
  let toHour = reportByCriteria['toHour'] ? reportByCriteria['toHour'] : '11';
  let toMinute = reportByCriteria['toMinute'] ? reportByCriteria['toMinute'] : '59';
  let toAmPm = reportByCriteria['toAmPm'] ? reportByCriteria['toAmPm'] : 'pm';

  return {
    fromDate,
    fromHour,
    fromMinute,
    fromAmPm,
    toDate,
    toHour,
    toMinute,
    toAmPm,

    accountName,
    username,
    statementNumber,
    dataSource,

    role: state.currentUserReducer.role,
    dataSourceTypes: state.dataSourceTypesReducer.codeDescriptionResultList,
    accounts: state.currentUserReducer.allowableAccounts
  };
}

class StatementReport extends Component {

  state = {
    fromDate: this.props.fromDate,
    toDate: this.props.toDate,
    fromHour: this.props.fromHour,
    fromMinute: this.props.fromMinute,
    fromAmPm: this.props.fromAmPm,
    toHour: this.props.toHour,
    toMinute: this.props.toMinute,
    toAmPm: this.props.toAmPm,

    accountName: this.props.accountName,
    username: this.props.username,
    statementNumber: this.props.statementNumber,
    dataSource: this.props.dataSource,

    showAccountList: false,
    showUserList: false,

    loadingStatus: "",
    errors: {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        const { loadingStatus, errors, ...request } = this.state; // remove UI only fields before submitting
        this.props.reportStatement(request, this.props.history, this.hideLoading);
      }
    }
  }

  handleExport = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.props.exportStatement(this.state, this.props.history, this.hideLoading);
      }
    }
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetReportCriteria();
    this.setState({
      fromDate: moment().format('L'),
      toDate: moment().format('L'),
      fromHour: '12',
      fromMinute: '0',
      fromAmPm: 'am',
      toHour: '11',
      toMinute: '59',
      toAmPm: 'pm',
  
      accountName: '',
      username: '',
      statementNumber: '',
      dataSource: 'FIVE',
  
      showAccountList: false,
      showUserList: false,
  
      loadingStatus: "",
      errors: {}
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    } 
    return false;
  }

  hideLoading = (message, status) => {
    if (status === 'ERROR') {
      alert(message);
    }
    this.setState({
      loadingStatus: ""
    })
  }

  handleAccountLookup = () => {
    this.setState( {
      showAccountList : true
    });
  }

  handleCloseAccountLookup = (selectedId, selectedName) => {
    this.setState({
      showAccountList: false,
      accountId: selectedId,
      accountName: selectedName
    })
  }
  
  handleUserLookup = () => {
    this.setState( {
      showUserList : true
    });
  }

  handleCloseUserLookup = (selectedId, selectedName) => {
    this.setState({
      showUserList: false,
      userId: selectedId,
      username: selectedName
    })
  }

  handleValidation() {
    let errors = {};
    if (!this.state.fromDate) {
      errors['fromDate'] = "Required.";
      this.setState({ errors: errors });
      return false;
    }

    let formIsValid = validateDateTimes(errors, this.state.fromDate, this.state.fromHour, this.state.fromMinute, this.state.fromAmPm, 'fromDate', this.state.toDate, this.state.toHour, this.state.toMinute, this.state.toAmPm, 'toDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }
    
    formIsValid = validateYearRangeLimit(errors, this.state.fromDate, 'fromDate', this.state.toDate, 'toDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleDatePickerChangeFromDate = (date) => {
    this.setState({
      fromDate : moment(date).format('L')
    })
  }

  handleDatePickerChangeToDate = (date) => {
    this.setState({
      toDate : moment(date).format('L')
    })
  }

  componentDidMount() {
    this.props.setSelectedReportType();
  }

  render() {

    let formGroupRows = [  {
      formGroups: [{ id: 'fromDate', label: 'From Date', fieldType: 'date', value: this.state.fromDate, handleDatePickerChange: this.handleDatePickerChangeFromDate, error: this.state.errors['fromDate'] },
      { id: 'fromHour', label: '', fieldType: 'select', options: hourOptions, value: this.state.fromHour },
      { id: 'fromMinute', label: '',fieldType: 'minute', options: minuteOptions, value: this.state.fromMinute },
      { id: 'fromAmPm', label: '', fieldType: 'select', options: amPmOptions, value: this.state.fromAmPm }]
    },
    {
      formGroups: [{ id: 'toDate', label: 'To Date', fieldType: 'date', value: this.state.toDate, handleDatePickerChange: this.handleDatePickerChangeToDate, error: this.state.errors['toDate'] },
      { id: 'toHour', label: '', fieldType: 'select', options: hourOptions, value: this.state.toHour },
      { id: 'toMinute', label: '', fieldType: 'minute', options: minuteOptions, value: this.state.toMinute },
      { id: 'toAmPm', label: '', fieldType: 'select', options: amPmOptions, value: this.state.toAmPm }]
    }]

    if (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') {
      formGroupRows.push({
        formGroups: [{ id: 'accountName', label: 'Account Name', fieldType: 'text', value: this.state.accountName },
                     { id: 'accountLookupButton', fieldType: 'lookup', onClick: this.handleAccountLookup }]
      });
    }
      
    formGroupRows.push({
        formGroups: [{ id: 'username', label: 'Username', fieldType: 'text', value: this.state.username },
                     { id: 'userLookupButton', fieldType: 'lookup', onClick: this.handleUserLookup }]
    });
    
    formGroupRows.push({
        formGroups: [{ id: 'statementNumber', label: 'Statement Number', fieldType: 'text', value: this.state.statementNumber }]
    });

    if (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') {
      formGroupRows.push({
        formGroups: [{ id: 'dataSource', label: 'Data Source', fieldType: 'select', options: this.props.dataSourceTypes, value: this.state.dataSource }]
      });
    }
  
    const formProps = {
      componentName: componentName,
      pageHeading: "Statement Report",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onSeconaryClick: this.handleExport,
      onClear: this.handleClear,
      primaryButtonLabel: "PDF",
      secondaryButtonLabel: "Excel",
      clearButtonLabel: "Clear",
      secondaryButtonBlue: true,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      formGroupRows: formGroupRows
    }

    return <>
    <FormTemplate {...formProps} />
    <AccountSelectionList showModal={this.state.showAccountList} codeDescriptionResults={this.props.accounts} onHide={(selectedId, selectedName) => this.handleCloseAccountLookup(selectedId, selectedName)} />
    <UserSelectionList  showModal={this.state.showUserList} onHide={(selectedId, selectedName) => this.handleCloseUserLookup(selectedId, selectedName)} />  
    </>
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(StatementReport);