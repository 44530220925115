import React, { Component } from "react"
import { Container, Col, Row, Table, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import SearchResultsDetailsNoResults from './SearchResultsDetailsNoResults'
import { loadDocuments, searchByTorrensCertificate, SearchByDocumentNumberUrl, SearchByNameUrl, selectSearchSequenceRow, setSelectedSearchType, SearchByNameKey, SearchByDocumentNumberKey, SearchByTorrensCertificateKey } from "../../../redux/actions/searchActions";
import Paginator from '../../common/page/Paginator'
import { getPaginatorHelper } from '../../common/page/PaginatorHelper'
import Loading from '../../common/modal/Loading';
import LegalDetails from './LegalDetails';
import { LOADING_STATUES } from "../../constants/constants";
import moment from 'moment';

export const SearchResultsDetailsGrantorsGranteesComponetName = 'SearchResultsDetailsGrantorsGrantees';
export const SearchResultsDetailsReferenceDocumentsComponetName = 'SearchResultsDetailsReferenceDocuments';
export const SearchResultsDetailsLegalDescriptionsComponetName = 'SearchResultsDetailsLegalDescriptions';
export const SearchResultsDetailsTorrensCertificatesComponetName = 'SearchResultsDetailsTorrensCertificates';


const mapStateToProps = state => {

    let searchHistory = state.searchHistoryReducer.searchHistoryList[0];

    let fromRecordedDate;
    let toRecordedDate;
    if (searchHistory && searchHistory.displayCriteria) {
        const fromRecordedDateCriteria = searchHistory.displayCriteria.filter(criteria => {
            return criteria.key === 'fromRecordedDate'
        })

        if (Array.isArray(fromRecordedDateCriteria) && fromRecordedDateCriteria.length > 0) {
            fromRecordedDate = fromRecordedDateCriteria[0].value;
        }

        const toRecordedDateCriteria = searchHistory.displayCriteria.filter(criteria => {
            return criteria.key === 'toRecordedDate'
        })
        if (Array.isArray(toRecordedDateCriteria) && toRecordedDateCriteria.length > 0) {
            toRecordedDate = toRecordedDateCriteria[0].value;
        }
    }

    return {
        showMapButton: state.countyConfigSearch2Reducer.map,
        showTaxButton: state.countyConfigSearch2Reducer.tax,
        searchResults: state.searchResultsReducer,
        searchResultsDetails: state.searchDetailsReducer,
        bookLabel: state.countyConfigCommonReducer.bookLabel,
        bookType: state.landlinkConfigReducer.bookType,
        displaySalePrice: state.landlinkConfigReducer.displaySalePrice,
        displayMortgageAmount: state.landlinkConfigReducer.displayMortgageAmount,
        mortgageAmountLabel: state.landlinkConfigReducer.mortgageAmountLabel,
        paginationPageGrantorsGrantees: state.searchDetailsGrantorsGranteesPaginationReducer,
        paginationPageLegalDescriptions: state.searchDetailsLegalDescriptionsPaginationReducer,
        paginationPageReferenceDocuments: state.searchDetailsReferenceDocumentsPaginationReducer,
        paginationPageTorrensCertificates: state.searchDetailsTorrensCertificatesPaginationReducer,
        // If the recorded dates from the original search are available, use them in the Grantor/Grantee link searches.
        fromRecordedDate: fromRecordedDate ? fromRecordedDate : moment('01/01/1900').format('L'),
        toRecordedDate: toRecordedDate ? toRecordedDate : moment().format('L'),
        currentUser: state.currentUserReducer,
        tableLoading: state.searchResultsTableLoadingReducer,
        currentSearch: state.searchHistoryReducer.searchHistoryList[0],
        currentSort: state.searchResultsSortReducer,
        currentPageNumber: state.searchResultsPaginationReducer,
        searchSequence: state.searchSequenceStartedReducer,
        searchSequenceSelectedDocumentId: state.searchResultsTableSelectedRowReducer
    };
}

const matchDispatchToProps = (dispatch) => {
    return {
        searchByTorrensCertificate: (searchCriteria, history, callback) => dispatch(searchByTorrensCertificate(searchCriteria, history, callback)),
        searchByName: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByNameUrl)),
        searchByDocumentNumber: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByDocumentNumberUrl)),
        startSearchSequence: (currentSearch) => dispatch({ type: 'START_SEARCH_SEQUENCE', search: currentSearch }),
        selectSearchSequenceRow: (id) => dispatch(selectSearchSequenceRow(id)),
        setSelectedSearchType: (searchType) => dispatch(setSelectedSearchType(searchType))
    }
}

class SearchResultsDetails extends Component {

    state = {
        loadingStatus: "",
        showLegalDetails: false
    }

    hideLoading = (message, status) => {
        if (status === 'ERROR') {
            alert(message);
        }

        this.setState({
            loadingStatus: ""
        })
    }

    showLegalDetails = (event) => {
        event.preventDefault();
        this.setState({
            showLegalDetails: true
        })
    }

    hideLegalDetails = () => {
        this.setState({
            showLegalDetails: false
        })
    }

    handleTorrensCertificateClick = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: LOADING_STATUES.LOADING
        })

        if (!this.props.searchSequence) {
            let currentSearchCriteria = this.props.currentSearch;
            currentSearchCriteria.pageNumber = this.props.currentPageNumber;
            currentSearchCriteria.sortKey = this.props.currentSort.sortKey;
            currentSearchCriteria.sortDirection = this.props.currentSort.sortDirection;
            this.props.startSearchSequence(currentSearchCriteria);
            this.props.selectSearchSequenceRow(this.props.searchResultsDetails);
        }

        let searchCriteria = {
            certificateNumber: event.target.getAttribute("data-certificate")
        }

        this.props.setSelectedSearchType(SearchByTorrensCertificateKey);
        this.props.searchByTorrensCertificate(searchCriteria, this.props.history, this.hideLoading);
    }

    handleGrantorGranteeClick = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: LOADING_STATUES.LOADING
        })

        if (!this.props.searchSequence) {
            let currentSearchCriteria = this.props.currentSearch;
            currentSearchCriteria.pageNumber = this.props.currentPageNumber;
            currentSearchCriteria.sortKey = this.props.currentSort.sortKey;
            currentSearchCriteria.sortDirection = this.props.currentSort.sortDirection;
            this.props.startSearchSequence(currentSearchCriteria);
            this.props.selectSearchSequenceRow(this.props.searchResultsDetails);
        }

        let searchCriteria = {
            grantorGranteeSearchIn: 'both',
            grantorGranteeLastName1: event.target.getAttribute("data-lastname"),
            grantorGranteeMiddleInitial1: event.target.getAttribute("data-middleinitial"),
            grantorGranteeFirstName1: event.target.getAttribute("data-firstname"),
            fromRecordedDate: this.props.fromRecordedDate,
            toRecordedDate: this.props.toRecordedDate
        }

        this.props.setSelectedSearchType(SearchByNameKey);
        this.props.searchByName(searchCriteria, this.props.history, this.hideLoading);
    }

    handleReferenceDocumentClick = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: LOADING_STATUES.LOADING
        })

        if (!this.props.searchSequence) {
            let currentSearchCriteria = this.props.currentSearch;
            currentSearchCriteria.pageNumber = this.props.currentPageNumber;
            currentSearchCriteria.sortKey = this.props.currentSort.sortKey;
            currentSearchCriteria.sortDirection = this.props.currentSort.sortDirection;
            this.props.startSearchSequence(currentSearchCriteria);
            this.props.selectSearchSequenceRow(this.props.searchResultsDetails);
        }

        let searchCriteria = {
            documentType: event.target.getAttribute("data-documenttype"),
            fromDocumentNumber: event.target.getAttribute("data-documentnumber"),
            toDocumentNumber: ''
        }

        this.props.setSelectedSearchType(SearchByDocumentNumberKey);
        this.props.searchByDocumentNumber(searchCriteria, this.props.history, this.hideLoading);
    }

    render() {
        const numberOfVisibleRecords = 3;

        let bookTypeLabel = "";
        if (this.props.bookType === true){
            bookTypeLabel = "/Type";
        }


        if (this.props.searchResultsDetails === 0) {
            return (<SearchResultsDetailsNoResults />)
        }

        let selectedSearchResult = this.props.searchResults.searchResultList.find((searchResult) => {
            return this.props.searchResultsDetails == searchResult.docId;
        });
        
        if (selectedSearchResult == null || selectedSearchResult === 0) {
            return (<SearchResultsDetailsNoResults />)
        }
        
        let { docType, displayDocNumber, docId, instrumentDescription, displayBookAndPage, recordedDateTime, instrumentDate,
            mortgageAmount, salePrice,
            grantorSearchResults, granteeSearchResults, verifiedStatus,
            displayOnlySearchResults, referenceDocumentSearchResults, additionalInfoSearchResults, legalSearchResults, torrensSearchResults } = selectedSearchResult || {};


        let grantorsGranteesArray = [];
        for (let i = 0; i < grantorSearchResults.length || i < granteeSearchResults.length; i++) {
            grantorsGranteesArray[i] = [grantorSearchResults[i], granteeSearchResults[i]];
        }


        let numberOfGrantorsGranteesPages = Math.ceil(grantorsGranteesArray.length / numberOfVisibleRecords);
        let paginationHelperGrantorsGrantees = getPaginatorHelper(this.props.paginationPageGrantorsGrantees, grantorsGranteesArray, numberOfVisibleRecords);

        let numberOfLegalSearchResultsPages = Math.ceil(legalSearchResults.length / numberOfVisibleRecords);
        let paginationHelperLegalDescriptions = getPaginatorHelper(this.props.paginationPageLegalDescriptions, legalSearchResults, numberOfVisibleRecords);

        let numberOfReferenceDocumentSearchResultsPages = Math.ceil(referenceDocumentSearchResults.length / numberOfVisibleRecords);
        let paginationHelperReferenceDocuments = getPaginatorHelper(this.props.paginationPageReferenceDocuments, referenceDocumentSearchResults, numberOfVisibleRecords);

        let numberOfTorrensSearchResultsPages = Math.ceil(torrensSearchResults.length / numberOfVisibleRecords);
        let paginationHelperTorrensCertificates = getPaginatorHelper(this.props.paginationPageTorrensCertificates, torrensSearchResults, numberOfVisibleRecords);

        let mortgageAmountLabel = 'Mortgage Amount';
        if (this.props.mortgageAmountLabel && this.props.mortgageAmountLabel != '') {
            mortgageAmountLabel = this.props.mortgageAmountLabel;
        }

        let salePriceMortgageAmountLabel = 'Sale Price/' + mortgageAmountLabel;
        let salePriceMortgageAmount = salePrice + "/" + mortgageAmount;
        if (this.props.displaySalePrice === true && this.props.displayMortgageAmount === false) {
            salePriceMortgageAmountLabel = 'Sale Price';
            salePriceMortgageAmount = salePrice;
        } else if (this.props.displaySalePrice === false && this.props.displayMortgageAmount === true) {
            salePriceMortgageAmountLabel = mortgageAmountLabel;
            salePriceMortgageAmount = mortgageAmount;
        }

        return (
            <>
                <Col style={{ paddingLeft: 0, marginRight: 0, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} >
                    <Container key={this.props.searchResultsDetails} style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 0, marginRight: 0 }} fluid="true">
                        <Row >

                            <Col>

                                <h5>
                                    <span><strong>{instrumentDescription}</strong> </span>
                                    <span className="badge badge-secondary">{verifiedStatus}</span>
                                    <span style={{ float: 'right' }}>
                                        {
                                            this.props.showMapButton === true &&
                                            <>
                                                <Button variant="primary" size="sm" onClick={this.props.handleGisClick}>Map</Button>
                                                &nbsp;
                                            </>
                                        }
                                        {
                                            this.props.showTaxButton === true &&
                                            <>
                                                <Button variant="primary" size="sm" onClick={this.props.handleTaxClick}>Tax</Button>
                                                &nbsp;&nbsp;
                                            </>
                                        }
                                        {
                                            this.props.currentUser.imageRetrieval &&
                                            <Button variant="primary" size="sm" onClick={this.props.handleImageClick} >Image</Button>
                                        }
                                    </span>
                                </h5>
                            </Col>

                        </Row>
                        <hr style={{ background: 'black', marginTop: 2, marginBottom: 2 }}></hr>

                        <div>
                            <div><strong>Document #: </strong>{displayDocNumber} &nbsp;</div>
                            <div><strong>Recorded Date and Time: </strong>{recordedDateTime} &nbsp;</div>
                            <div><strong>Instrument Date: </strong>{instrumentDate}</div>
                            <div><strong>{this.props.bookLabel}/Page{bookTypeLabel}: </strong>{displayBookAndPage}</div>
                            <div><strong>{salePriceMortgageAmountLabel}: </strong>{salePriceMortgageAmount}</div>
                            {
                                docType === 'T' &&
                                <>
                                    <Table striped bordered hover variant="dark" size="sm" style={{ marginBottom: 0, marginTop: 10 }}>
                                        <thead><tr><th style={{ width: '50%' }} scope="col">Certificates</th><th style={{ width: '50%' }} scope="col">Status</th></tr></thead>
                                        <tbody>
                                            {
                                                paginationHelperTorrensCertificates.currentPageRecordList.map(certificate => {
                                                    return <tr>
                                                        <td className="table-td-xs">
                                                            {
                                                                this.props.tableLoading === true &&
                                                                <span>{certificate.certificateNumber}</span>
                                                            }
                                                            {
                                                                this.props.tableLoading !== true &&
                                                                <a name="searchByTorrensCertificate" href='#BogusToRemoveWarning' style={{ textDecoration: 'none' }} data-certificate={certificate.certificateNumber} onClick={this.handleTorrensCertificateClick}> {certificate.certificateNumber}</a>
                                                            }
                                                        </td>
                                                        <td className="table-td-xs">
                                                            {certificate.status}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col><Paginator componentName={SearchResultsDetailsTorrensCertificatesComponetName} numberOfPages={numberOfTorrensSearchResultsPages} numberOfVisibleRecords={numberOfVisibleRecords}  ></Paginator></Col>
                                    </Row>
                                </>
                            }

                            <Table striped bordered hover variant="dark" size="sm" style={{ marginBottom: 0, marginTop: 10 }}>
                                <thead><tr><th scope="col">Grantors</th><th scope="col">Grantees</th></tr></thead>
                                <tbody>
                                    {
                                        paginationHelperGrantorsGrantees.currentPageRecordList.map((grantorGrantee) => {
                                            return <tr>
                                                <td className="table-td-xs" style={{ width: '50%' }} >
                                                    {
                                                        this.props.tableLoading === true &&
                                                        <span>{grantorGrantee[0] ? grantorGrantee[0].displayName : ''} </span>
                                                    }
                                                    {
                                                        this.props.tableLoading !== true &&
                                                        <a href='#BogusToRemoveWarning' name="searchByName" style={{ textDecoration: 'none' }} data-lastname={grantorGrantee[0] ? grantorGrantee[0].lastName : ''} data-firstname={grantorGrantee[0] ? grantorGrantee[0].firstName : ''} data-middieinitial={grantorGrantee[0] ? grantorGrantee[0].middleInitial : ''} onClick={this.handleGrantorGranteeClick}> {grantorGrantee[0] ? grantorGrantee[0].displayName : ''} </a>
                                                    }
                                                </td>
                                                <td className="table-td-xs" style={{ width: '50%' }} >
                                                    {
                                                        this.props.tableLoading === true &&
                                                        <span> {grantorGrantee[1] ? grantorGrantee[1].displayName : ''} </span>
                                                    }
                                                    {
                                                        this.props.tableLoading !== true &&
                                                        <a href='#BogusToRemoveWarning' name="searchByName" style={{ textDecoration: 'none' }} data-lastname={grantorGrantee[1] ? grantorGrantee[1].lastName : ''} data-firstname={grantorGrantee[1] ? grantorGrantee[1].firstName : ''} data-middleinitial={grantorGrantee[1] ? grantorGrantee[1].middleInitial : ''} onClick={this.handleGrantorGranteeClick}> {grantorGrantee[1] ? grantorGrantee[1].displayName : ''} </a>
                                                    }
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>

                            <Row >
                                <Col ><Paginator style={{ marginBottom: 0 }} componentName={SearchResultsDetailsGrantorsGranteesComponetName} numberOfPages={numberOfGrantorsGranteesPages} numberOfVisibleRecords={numberOfVisibleRecords} ></Paginator></Col>
                            </Row>

                            <Table striped bordered hover variant="dark" size="sm" style={{ marginBottom: 0, marginTop: 10 }}>
                                <thead><tr><th style={{ width: '50%' }} scope="col">Legal Description&nbsp;<Button style={{ fontSize: '0.7em', fontWeight: 'bold', paddingTop: 0, paddingBottom: 1 }} variant="primary" onClick={this.showLegalDetails} >Details</Button> </th><th style={{ width: '50%' }} scope="col">Comment</th></tr></thead>
                                <tbody>
                                    {
                                        paginationHelperLegalDescriptions.currentPageRecordList.map(legalDescription => {
                                            return <tr id={legalDescription.docId}>
                                                <td className="table-td-xs">
                                                    {legalDescription.description}
                                                </td>
                                                <td className="table-td-xs">
                                                    {legalDescription.comment}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Row>
                                <Col><Paginator style={{ marginBottom: 0 }} componentName={SearchResultsDetailsLegalDescriptionsComponetName} numberOfPages={numberOfLegalSearchResultsPages} numberOfVisibleRecords={numberOfVisibleRecords}  ></Paginator></Col>
                            </Row>

                            <Table striped bordered hover variant="dark" size="sm" style={{ marginBottom: 0, marginTop: 10 }}>
                                <thead><tr><th style={{ width: '34%' }} scope="col">Reference Document</th><th style={{ width: '33%' }} scope="col">Document #</th><th style={{ width: '33%' }} scope="col">Recorded Date</th></tr></thead>
                                <tbody>
                                    {
                                        paginationHelperReferenceDocuments.currentPageRecordList.map(reference => {
                                            return <tr id={reference.docId}>
                                                <td className="table-td-xs">
                                                    {reference.instrumentDescription}
                                                </td>
                                                <td className="table-td-xs">
                                                    {
                                                        this.props.tableLoading === true &&
                                                        <span> {reference.displayDocNumber} </span>
                                                    }
                                                    {
                                                        this.props.tableLoading !== true &&
                                                        <a name="searchByDocumentNumber" href='#BogusToRemoveWarning' style={{ textDecoration: 'none' }} data-documentnumber={reference.docNumber} data-documenttype={reference.docType} onClick={this.handleReferenceDocumentClick}> {reference.displayDocNumber}</a>
                                                    }
                                                </td>
                                                <td className="table-td-xs">
                                                    {reference.recordedDate} {reference.recordedTime}{reference.recordedAmPm}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Row>
                                <Col><Paginator componentName={SearchResultsDetailsReferenceDocumentsComponetName} numberOfPages={numberOfReferenceDocumentSearchResultsPages} numberOfVisibleRecords={numberOfVisibleRecords}  ></Paginator></Col>
                            </Row>
                            {
                                // Showing the Additional Info section only if there's any additional info
                                additionalInfoSearchResults.length > 0 &&
                                <Table striped bordered hover variant="dark" size="sm" style={{ marginBottom: 0, marginTop: 10 }}>
                                    <thead><tr><th scope="col">Additional Info</th></tr></thead>
                                    <tbody>
                                        {
                                            additionalInfoSearchResults.map(additionalInfo => {
                                                return <tr><td>{additionalInfo}</td></tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                            }
                            <div style={{ marginTop: 10 }}></div>
                            {
                                displayOnlySearchResults.map((displayOnlySearchResult) => {
                                    return <div><strong>{displayOnlySearchResult.label}: </strong>{displayOnlySearchResult.value}</div>
                                })
                            }

                        </div>
                    </Container>
                </Col>
                <Loading loadingIndicator={this.state.loadingStatus === 'LOADING'} />
                <LegalDetails show={this.state.showLegalDetails} hideLegalDetails={this.hideLegalDetails} title={displayDocNumber} key={docId} docId={docId} />
            </>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchResultsDetails);